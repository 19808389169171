import React from "react";
import Header from '../components/headerComponent';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import ArticleHomeComponent from '../components/Blog/articleHomeComponent';
import ArticleComponent from '../components/Blog/articleComponent';
import NewFooterComponent from '../components/newFooterComponent';
const FromStrategyToDelivery = (props) =>{
    const {
      data: {
        wpPost: {
          seo,
          blogArticleSection: {
            blogArticleAuthor,
            blogArticleData,
            blogArticleReadingTime,
            blogArticleText,
            blogArticleTitle,
            blogArticleImage: {
              localFile: {
                childImageSharp: { gatsbyImageData: blogArticleImage },
              },
            },
            blogRecommendedArticleEmployeesImage: {
              localFile: {
                childImageSharp: {
                  gatsbyImageData: blogRecommendedArticleEmployeesImage,
                },
              },
            },
            blogRecommendedArticleStrategyImage: {
              localFile: {
                childImageSharp: {
                  gatsbyImageData: blogRecommendedArticleStrategyImage,
                },
              },
            },
            blogRecommendedArticleStudentsImage: {
              localFile: {
                childImageSharp: {
                  gatsbyImageData: blogRecommendedArticleStudentsImage,
                },
              },
            },
            blogArticleSectionTwoText,
            blogArticleSectionThreeText,
            blogArticleSecondFore,
            blogArticleSecondImage: {
              localFile: {
                childImageSharp: { gatsbyImageData: blogArticleSecondImage },
              },
            },
            blogArticleThirdImage: {
              localFile: {
                childImageSharp: { gatsbyImageData: blogArticleThirdImage },
              },
            },
            blogArticleForthImage: {
              localFile: {
                childImageSharp: { gatsbyImageData: blogArticleForthImage },
              },
            },
          },
        },
      },
      pageContext: { title },
    } = props;
    return (
      <Layout title={title} seo={seo}>
        <Header />
        <ArticleHomeComponent
          articleHomeTitle={blogArticleTitle}
          articleHomeData={blogArticleData}
          articleHomeAuthor={blogArticleAuthor}
          articleImage={blogArticleImage}
          articleHomeTimeForReading={blogArticleReadingTime}
          blogArticleStrategyImage={blogRecommendedArticleStrategyImage}
          blogArticleStudentsImage={blogRecommendedArticleStudentsImage}
          blogArticleStopSharingIdeasImage={
            blogRecommendedArticleEmployeesImage
          }
        />
        <ArticleComponent
          articleText={blogArticleText}
          blogArticleStrategyImage={blogRecommendedArticleStrategyImage}
          blogArticleStudentsImage={blogRecommendedArticleStudentsImage}
          blogArticleStopSharingIdeasImage={
            blogRecommendedArticleEmployeesImage
          }
          articleDescription={blogArticleSectionTwoText}
          articleSubDescription={blogArticleSectionThreeText}
          articleSecondImage={blogArticleSecondImage}
          articleThirdImage={blogArticleThirdImage}
          articleForthImage={blogArticleForthImage}
          articleEndDescription={blogArticleSecondFore}
          tagsName="Change Management"
          categoriesName="Innovation"
          blogArticleTitle="From Strategy to Delivery - closing the gap"
          blogArticleData="23 Mar 2022"
          blogArticleTime="9 min read"
          blogArticleTwoData="15 Sep 202"
          blogArticleTwoTime="5 min read"
          blogArticleTwoTitle="Cultivating the start-up and innovation mindset in collage"
          blogArticleThreeData="21 Jul 2022"
          blogArticleThreeTime="4 min read"
          blogArticleThreeTitle="Why idea sharing in universities promotes diversity and inclusion"
          firstArticleRecommendedLink="/blog/from-strategy-to-delivery-closing-the-gap"
          SecondArticleRecommendedLink="/blog/cultivating-the-start-up-and-innovation-mindset-in-college"
          thirdArticleRecommendedLink="/blog/why-idea-sharing-in-universities-promotes-diversity-and-inclusion"
        />
        <NewFooterComponent isProduct={true} />
      </Layout>
    );
}
export const query = graphql`
  query {
    wpPost(slug: { eq: "from-strategy-to-delivery-closing-the-gap-2" }) {
      seo {
        metaDesc
        title
      }
      blogArticleSection {
        blogArticleAuthor
        blogArticleData
        blogArticleReadingTime
        blogArticleText
        blogArticleTitle
        blogArticleImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        blogRecommendedArticleEmployeesImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        blogRecommendedArticleStrategyImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        blogRecommendedArticleStudentsImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        blogArticleSectionTwoText
        blogArticleSectionThreeText
        blogArticleSecondFore
        blogArticleSecondImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        blogArticleThirdImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        blogArticleForthImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`;
export default FromStrategyToDelivery;